import styled from "styled-components";
import HeaderText3 from "../Text/HeaderText3";
import WhoButton from "../Home/Whoweare/WhoButton";
const AboutContent = () => {
  const instagramProfileUrl = "https://www.instagram.com/7interactive/";
  return (
    <AboutCont>
      <div className="container div py-5">
        <div className="content">
          <div className="layer1">
            <div className="web">
              <div className="row">
                <div className="vision col-lg-4 col-md-4">
                  <div>
                    <HeaderText3 title1="Vision" />
                  </div>
                </div>
                <div className="values col-lg-4 col-md-4">
                  <HeaderText3 title1="Values" />
                </div>
                <div className="mission col-lg-4 col-md-4">
                  <HeaderText3 title1="Mission" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="vision col-lg-4 col-md-4">
                  <div>
                    <li className="visionp">
                      <li>To be the best INNOVATIVE SOLUTIONS-</li>PROVIDING
                      company that builds successful
                      <br />
                      businesses.
                    </li>
                  </div>
                </div>
                <div className="values col-lg-4 col-md-4">
                  <li>Openess</li>
                  <li>Collaboration</li>
                  <li>Mutual Respect</li>
                  <li>Family Ties</li>
                </div>
                <div className="mission col-lg-4 col-md-4">
                  <li>
                    We daily leverage DATA & TECHNOLOGY to pursue and deliver
                    INNOVATIVE SOLUTIONS to be the top choice for brands looking
                    for solutions and to attain prominence in the advertising
                    industry.
                  </li>
                </div>
              </div>
              <div className="mob">
                <div className="vision">
                  <HeaderText3 title1="Vision" />
                  <li>
                    To be the best INNOVATIVE SOLUTIONS PROVIDING company that
                    builds successful businesses.
                  </li>
                </div>
                <div className="values">
                  <HeaderText3 title1="Values" />
                  <li>Openess</li>
                  <li>Collaboration</li>
                  <li>Mutual Respect</li>
                  <li>Family Ties</li>
                </div>
                <div className="mission">
                  <HeaderText3 title1="Mission" />
                  <li>
                    We daily leverage DATA & TECHNOLOGY to pursue and deliver
                    INNOVATIVE SOLUTIONS to be the top choice for brands looking
                    for solutions and to attain prominence in the advertising
                    industry.
                  </li>
                </div>
              </div>
            </div>
            <div className="videobox mt-5">
              <IframeContainer>
                <iframe
                  src="https://www.youtube.com/embed/qT51m6YbUkM?si=j3MlL2iPfH1dACSR"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </IframeContainer>
            </div>
            {/* <div className="textdiv2"></div> */}
            <div className="textdiv3 mt-5">
              <p>
                7i or 7even interactive is a 360⁰ marketing communications
                (advertising) agency that was established in 2015.
              </p>
              <li>
                At 7i, we are strong believers that this world is made of dreams
                and great innovations are dreams that came true. For this
                reason, we are passionate about successfully bringing dreams to
                life for our clients as well as the staff. Ours is a vibrant and
                energetic agency with a culture of family, fun and focus. We
                love the work we do; we appreciate a good challenge and we enjoy
                using our creative talents to solve business problems.
              </li>
            </div>
          </div>

          <div className="layer2 mt-5">
            <div className="image-left">
              <img src="/img/left1.png" alt="team-" />
              <img src="/img/left2.png" alt="team-award" />

              <img src="/img/left3.png" alt="team-art" />
            </div>
            <div className="image-right">
              <img src="/img/right.png" alt="team-picnic" />
            </div>
          </div>
          <div className="button mt-5">
            <a
              href={instagramProfileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhoButton title="Follow us" />
            </a>
          </div>
        </div>
      </div>
    </AboutCont>
  );
};

const AboutCont = styled.div`
  background-color: white;
  // height:100vh;
  a {
    text-decoration: none;
  }

  .mob {
    display: none;
  }
  .parentrow {
    display: flex;
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
  }

  .textdiv1 {
    background-color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vision {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    // width:100%;
  }

  .values {
    // width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mission {
    /* width:100%; */
  }

  li {
    font-weight: 400;
    font-size: 16px;
    /* line-height: 26px; */
    letter-spacing: -0.3px;
    color: #000723;
    list-style-type: none;
    font-family: "MavenRegular",Times;

  }

  p {
    color: #001033;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 50px; 125% */
    letter-spacing: -0.6px;
    font-family: "MarkPro", sans-serif;

  }

  .visionp {
    text-align: right;
    font-weight: 400;
    font-size: 17px;
    // padding-right:100px;
    /* line-height: 24px; */
    color: #000723;
  }

  img {
    width: 100%;
    height: auto;
    // height:802px;
  }
  .layer2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
    height: 100%;
  }

  .image-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    .layer2 {
      display: flex;
      // flex-direction:column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    p {
      font-size: 34px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    .layer2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    li {
      font-size: 22px;
    }

    p {
      font-size: 32px;
    }
  }

  @media (max-width: 912px) {
    .layer2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .mob {
      display: none;
    }

    .image-left {
      gap: 1rem;
    }
    .image-right {
      gap: 1rem;
    }
    li {
      font-size: 18px;
    }

    p {
      font-size: 26px;
    }
  }

  @media (max-width: 640px) {
    .layer2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    li {
      font-size: 16px;
    }

    p {
      font-size: 26px;
    }
    .vision {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        text-align: center;
        padding: 10px 30px;
      }
    }

    .values {
      // width:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mission {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        text-align: center;
        padding: 10px 20px;
      }
    }
    .mob {
      display: block;
    }
    .row {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .layer2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .vision {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        text-align: center;
        padding: 10px 40px;
      }
    }

    .values {
      // width:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mission {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        text-align: center;
        padding: 10px 20px;
      }
    }

    .mob {
      display: block;
    }
    .row {
      display: none;
    }
  }
`;
const IframeContainer = styled.div`
  iframe {
    width: 100%; /* Set your desired width here */
    height: 650px; /* Set your desired height here */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    iframe {
      width: 100%;
      height: 450px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    iframe {
      width: 100%;
      height: 450px;
    }
  }
  @media (max-width: 912px) {
    iframe {
      width: 100%; /* Set your desired width here */
      height: 350px; /* Set your desired height here */
    }
  }

  @media (max-width: 640px) {
    iframe {
      width: 100%; /* Set your desired width here */
      height: 350px; /* Set your desired height here */
    }
  }

  @media (max-width: 480px) {
    iframe {
      width: 100%; /* Set your desired width here */
      height: 250px; /* Set your desired height here */
    }
  }
`;
export default AboutContent;
